<script>
import { XIcon } from '@heroicons/vue/solid'

export default {
  components: {
    XIcon,
  },
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
}
</script>

<template>
  <button
    class="group flex gap-2 rounded-lg border border-sortera-600/25 bg-sortera-500/10 p-1 text-sortera-700 transition-all focus:outline-none"
    :class="{
      'cursor-pointer hover:border-sortera-700/25 hover:bg-sortera-600/20':
        !disabled,
      ' cursor-auto': disabled,
      'px-2 leading-snug': size === 'md',
      'px-2 text-sm leading-tight': size === 'sm',
      'px-1 text-xs leading-4': size === 'xs',
    }"
    v-bind="$attrs"
  >
    <slot />

    <XIcon v-if="!hideIcon" class="my-auto h-3 w-3" />
  </button>
</template>
