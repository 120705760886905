import { createStore } from 'vuex'

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => ({
    currentOrg: null,
    currentProj: null,
    currentVehicle: null,
  }),
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) if (key in state) state[key] = payload[key]
    },
  },
})
