<script>
export default {
  props: {
    user: {
      default: null,
      type: Object,
    },
    name: {
      default: null,
      type: String,
    },
    data: {
      default: null,
      type: String,
    },
    mask: {
      default: 'mask-squircle',
      type: String,
    },
    hideTooltip: {
      default: false,
      type: Boolean,
    },
    round: {
      default: false,
      type: Boolean,
    },
    useInitials: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    cleanName() {
      if (this.name && this.name.includes('@'))
        return this.name.split('@')[0].replaceAll('.', ' ')
      return this.name
    },
    imageData() {
      if (this.user && this.user.imageData) return this.user.imageData
      if (this.data && this.data.length > 0) return this.data
      if (this.name && this.name.length > 0)
        return `https://icotar.com/${
          this.useInitials ? 'initials' : 'avatar'
        }/${encodeURIComponent(this.cleanName)}`
      return 'https://i.imgur.com/L2Py2dG.png'
    },
  },
}
</script>

<template>
  <div class="avatar">
    <div class="mask" :class="[{ 'rounded-full': round }, mask]">
      <img
        :title="cleanName && !hideTooltip ? cleanName : null"
        :src="imageData"
        class="object-cover"
      />
    </div>
  </div>
</template>
