import { db } from '@/plugins/db'
import store from '@/state/store'

/** @type {import('vue-router').RouterOptions['routes']} */
export default [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/home.vue'),
  },
  {
    path: '/org/:orgCode',
    name: 'org',
    component: () => import('./layouts/main.vue'),
    redirect: to => ({ name: 'overview', params: to.params }),
    meta: {
      authRequired: true,
      async beforeResolve(to, from, next) {
        if (store.currentOrg && store.currentOrg.code === to.params.orgCode)
          next()
        else {
          const snapshot = await db
            .collection('organizations')
            .doc(to.params.orgCode)
            .get()
          if (!snapshot.exists) {
            next({ path: '/', replace: true })
          } else {
            let doc = snapshot.data()
            store.commit('SET_STATE', { currentOrg: doc })

            next()
          }
        }
      },
    },
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: () => import('./views/overview.vue'),
      },
      {
        path: 'project/:projCode',
        name: 'proj',
        component: () => import('./views/project.vue'),
        meta: {
          async beforeResolve(to, from, next) {
            if (
              store.currentProj &&
              store.currentProj.code === to.params.projCode
            )
              next()
            else {
              const snapshot = await db
                .collection('projects')
                .doc(to.params.projCode)
                .get()
              if (!snapshot.exists) {
                next({ name: 'home', replace: true })
              } else {
                let doc = snapshot.data()
                store.commit('SET_STATE', { currentProj: doc })

                next()
              }
            }
          },
        },
      },
      {
        path: 'vehicle/:vehicleCode',
        name: 'vehicle',
        component: () => import('./views/vehicle.vue'),
        meta: {
          async beforeResolve(to, from, next) {
            if (
              store.currentVehicle &&
              store.currentVehicle.code === to.params.vehicleCode
            )
              next()
            else {
              const snapshot = await db
                .collection('vehicles')
                .doc(to.params.vehicleCode)
                .get()
              if (!snapshot.exists) {
                next({ name: 'home', replace: true })
              } else {
                let doc = snapshot.data()
                store.commit('SET_STATE', { currentVehicle: doc })

                next()
              }
            }
          },
        },
      },
    ],
  },
  {
    path: '/org',
    name: 'gate',
    component: () => import('./views/gate.vue'),
  },
  {
    path: '/bil/:code',
    name: 'driver',
    component: () => import('./views/driver.vue'),
    meta: {
      async beforeResolve(to, from, next) {
        if (
          store.currentVehicle &&
          store.currentVehicle.code === to.params.code
        )
          next()
        else {
          const snapshot = await db
            .collection('vehicles')
            .doc(to.params.code)
            .get()
          if (!snapshot.exists) {
            next({ name: 'home', replace: true })
          } else {
            let doc = snapshot.data()
            store.commit('SET_STATE', { currentVehicle: doc })

            next()
          }
        }
      },
    },
  },
  {
    path: '/admin',
    meta: { authRequired: true },
    component: () => import('./views/admin.vue'),
  },
  { path: '/:path(.*)', component: () => import('./views/_404.vue') },
]
