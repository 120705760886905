import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import router from './router'
app.use(router)

import store from './state/store'
app.use(store)

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://d6c748edb5ae4b8c83bbc6fdf86a1e37@o1273612.ingest.sentry.io/6468047',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['transport.sortera.org', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

import Tag from '@/components/tag.vue'
app.component('Tag', Tag)

import Avatar from '@/components/avatar.vue'
app.component('Avatar', Avatar)

import { firestorePlugin } from 'vuefire'
app.use(firestorePlugin, { wait: true })

import { Settings } from 'luxon'
Settings.defaultLocale = 'sv'
Settings.defaultZoneName = 'utc'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.se.js'
app.use(Cleave)

import VueGoogleMaps from '@fawmi/vue-google-maps'
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDUy-1lcUmTOmXKQkKFNycMbzl0hsJHJac',
    libraries: 'places,directions',
  },
})

import Pagination from 'v-pagination-3'
app.component('Pagination', Pagination)

app.mount('#app')
