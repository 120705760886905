import { msalConfig } from './authConfig'
import * as msal from '@azure/msal-browser'
import * as Sentry from '@sentry/browser'

const msalInstance = new msal.PublicClientApplication(msalConfig)

msalInstance
  .handleRedirectPromise()
  .then(async response => {
    if (!response) return

    const request = {
      account: response.account,
      redirectUri: msalConfig.auth.redirectUri,
    }

    await msalInstance.acquireTokenSilent(request)
  })
  .catch(error => {
    console.error(error)
  })

const createBackendRequest = response => {
  return {
    account: response.account,
    redirectUri: msalConfig.auth.redirectUri,
  }
}

export default {
  loggedInUser() {
    if (this.isAuthenticated()) {
      const name = this.getUser().username.toLowerCase()
      if (name !== '') return name
    }
    return null
  },

  loginRedirect() {
    return msalInstance.loginRedirect()
  },

  handleRedirect() {
    return msalInstance.handleRedirectPromise()
  },

  signOut() {
    const signedInUser = msalInstance.getAllAccounts()[0]
    const logoutRequest = {
      account: msalInstance.getAccountByUsername(signedInUser.username),
      postLogoutRedirectUri: window.location.origin,
    }

    msalInstance.logoutRedirect(logoutRequest)
  },

  async isAuthenticated() {
    const signedInUser = msalInstance.getAllAccounts()[0]

    if (!signedInUser) return false

    const response = await this.acquireTokenSilent().catch(() => {
      console.error('User is not authenticated')
      return false
    })

    if (!response) return false

    return true
  },

  async loginPopup() {
    const response = await msalInstance.loginPopup({})

    const backendTokenRequest = createBackendRequest(response)
    return msalInstance.acquireTokenSilent(backendTokenRequest)
  },

  async acquireTokenSilent() {
    const signedInUser = msalInstance.getAllAccounts()[0]
    const silentRequest = {
      account: signedInUser,
      scopes: ['openid', 'profile', 'user.read', 'email'],
    }

    const response = await msalInstance.acquireTokenSilent(silentRequest)
    const backendRequest = createBackendRequest(response)
    return msalInstance.acquireTokenSilent(backendRequest)
  },

  getUser() {
    const user = msalInstance.getAllAccounts()[0]
    if (user) {
      Sentry.setUser({ email: user.username })
      return user
    }
    return { username: '' }
  },
}
