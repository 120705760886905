export const msalConfig = {
  auth: {
    clientId: '42c76df3-2bc3-4bf4-acb5-059591100501',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    authority:
      'https://login.microsoftonline.com/6e4277b8-d8e0-4674-ba2e-b043ad84cc9e',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    // cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}
